<template>
    <main class="workshop workshop__Overview">
    <ToolkitIntro :toolkit="$t('overviewToolkit')"></ToolkitIntro>
      <div class="workshop_content">
      <h2>Purpose, Values & Goals</h2>
      <p>This multimedia organizing toolkit is a collection of activities, how-to guides, facilitation plans, and resources that organizers and residents can use to fight displacement in their communities.</p>
        <p>Chelsea and Everett, Massachusetts are facing pressure from developers, resulting in land being swallowed up by investors and increased housing prices, and the forced displacement (or pushing-out) of residents and small businesses. These two communities have been involved in the <ExternalLink href="https://www.clf.org/healthy-neighborhoods-study/">Healthy Neighborhoods Study (HNS)</ExternalLink>, a participatory action research project which aims to understand the relationship between development, neighborhood conditions, and health across nine communities in Massachusetts. HNS research from 2016-2019 indicates that 40% of Chelsea and 31% of Everett survey respondents expect to move in the next five years; of that research, 72% of Chelsea and 75% of Everett respondents indicated that they are proud to live in their community. However, the impact of displacement will negatively affect these respondents, other individuals, families, and communities. To learn more about displacement, including its impact on health, educational outcomes, and community power, view <ExternalLink href="https://www.urbandisplacement.org/pushedout"><b>Pushed Out</b></ExternalLink>, a short video produced by Urban Displacement Project.</p>
      <p>Ensuring that our communities remain viable options in which residents can live and thrive is within our power. As we face continued large-scale developments and planning initiatives such as the East Boston Master Plan, the Chelsea Creek Municipal Harbor Plan, or the Encore Casino in Everett, it's important and timely for the local community to address displacement.</p>
      <p>We wanted to create a guide for community members to work together to protect and preserve their communities. Our goal is for you, the user, to build power through collective analysis (from your own knowledge), create shared goals and actions, and develop guiding principles of equitable development.</p>
      <h3>VALUES AND GOALS OF THIS TOOLKIT</h3>
      <ul>
        <li>Solidarity: Cultivate Collective Power by Using Knowledge in the Room</li>
        <li>Action-Oriented: Think + Do</li>
        <li>Hyper-Accessibility: Multi-Generational + Multi-Lingual</li>
        <li>Community Rootedness: Hyper-Local + Shared + Generalizable</li>
      </ul>
      <h2>How to Use this Toolkit to fight displacement in Greater Boston</h2>
      <p>As communities protect themselves and organize against displacement, this toolkit can be a resource for residents working together to create change. Communities most vulnerable to displacement are the most in touch with what is happening on the ground, and know which solutions will work best for themselves. This toolkit will help residents, neighborhood councils, organizations, and anyone organizing against displacement engage with each other in meaningful ways. Whether a community is trying to further understand how displacement shows up, create awareness among allies, or push for specific policy and programmatic solutions, this toolkit can help. It can be used to build power within residents, as well as push for concrete changes within the community. Through the use of data and stories, it touches the minds and hearts of those who care deeply about their community and can implement change.</p>
      <p>This toolkit can be used by anyone wanting to work with others to fight displacement in their community, no matter their organizing experience. With easy-to-read, translatable, and accessible facilitation guides—whether you’re an experienced organizer, a service provider, a tenant or a homeowner, a life-long resident or newly arrived—this toolkit is for you and your community!</p>
      <p>Protection of residents and local business, production of affordable housing, and preservation of existing affordable housing stock are all key pieces of preventing displacement. Multiple possibilities lie in actions that can and should be taken—there is no one-size-fits-all solution. A community’s wants and needs must come from the ground up—and we hope this toolkit will help you get there!</p>
      <h2>Who was involved in creating this Anti-Displacement Toolkit?</h2>
      <p>This toolkit was made possible by the contributions of many organizations and community members, whom we thank for sharing their time, expertise, and commitment.</p>
      <h3>COMMUNITY PARTNER ORGANIZATIONS</h3>
        <table>
          <tr>
            <td class="partnerLogos">
              <img src="../../assets/partnerLogos/echp-logo-no-sac-copy2.png" style="{ max-width: 8em; }" alt="Everett Community Health Partnership Logo">
              <img src="../../assets/partnerLogos/CHA_logo.png" alt="Cambridge Health Alliance Logo">
            </td>
            <td><p><a href="https://www.challiance.org/community/everett-health-partnership" class="org__name">The Everett Community Health Partnership (ECHP)</a> is a multisector community coalition facilitated by the Cambridge Health Alliance Department of Community Health Improvement. ECHP seeks to improve the health and quality of life in Everett by creating opportunities to strategically address challenges facing the community, raising awareness about the impact of development on neighborhood well-being, and ensuring that critical data regarding community health is made widely available. ECHP is a community research partner with Healthy Neighborhoods Study, a participatory action research study co-led by the Conservation Law Foundation, Mariana Arcaya and MIT Community Innovators Lab (CoLab).</p>
          <p class="italic">Kathleen O’Brien with Cambridge Health Alliance, Iliana Panameno and Yrma Fiestas with La Comunidad, Inc.</p>
      </td>
          </tr>
          <tr>
            <td class="partnerLogos">            <img src="../../assets/partnerLogos/GreenRootsLogo_flat.png" alt="Green Roots Logo">
            </td>
            <td>
        <p><a href="https://www.greenrootschelsea.org/" class="org__name">GreenRoots</a>, a resident-led, community-based organization, is dedicated to improving and enhancing urban environment and public health in Chelsea and surrounding communities. GreenRoots carries out its work with deep community engagement and empowerment, youth leadership, and implementation of innovative projects and campaigns. GreenRoots is a community research partner with Healthy Neighborhoods Study, a participatory action research study co-led by the Conservation Law Foundation, Mariana Arcaya and MIT Community Innovators Lab (CoLab).
        </p>
        <p class="italic">Maria Belen Power, Indira Garmendia Alfaro</p>
            </td></tr>
        </table>
        <p>We thank all community partners and members for their participation and thoughtful feedback at the toolkit co-design session.</p>
        <p>We thank Lisa Owens and Andres del Castillo with <a href="https://www.clvu.org">City Life/Vida Urbana</a> for their participation and contributions to the initial concept and co-design of this toolkit.</p>
      <h3>COORDINATING ORGANIZATIONS</h3>
  <table><tr><td class="partnerLogos"><img src="../../assets/partnerLogos/RAD_logo3_large---transparency.png" alt="Research Action Design Logo">
  </td>
    <td>
        <p><a href="https://rad.cat" class="org__name">Research Action Design (RAD)</a> uses community-led research, collaborative design of technology and media, and secure digital strategies to build the power of grassroots social movements. RAD is a worker-owned collective whose projects are grounded in the needs and leadership of communities in the struggle for justice and liberation.</p>
        <p class="italic">Caroline Rivas, Chris Schweidler, Tim Stallmann</p>
    </td>
  </tr>
    <tr><td class="partnerLogos"><img src="../../assets/partnerLogos/colab-logo.png" alt="MIT Community Innovators Lab Logo">
    </td>
      <td>
        <p><a href="https://www.colab.mit.edu" class="org__name">MIT Community Innovators Lab (CoLab)</a> supports the development and use of knowledge from excluded communities to deepen civic engagement, improve community practice, inform policy, mobilize community assets, and generate shared wealth. We believe in the power of community-driven knowledge and its potential to innovate solutions to complex urban challenges. CoLab facilitates the interchange of knowledge and resources between MIT and community organizations, engaging students and faculty to be practitioners of this approach to community change and sustainability.</p>
        <p class="italic">Allegra Williams, Andrew Binet, Agustín Cepeda, Natalia Coachman, Taina McField, Taylor Cain.</p>
      </td></tr>
  </table>
        <p>The film, <b>Desplazada</b>, for the toolkit was produced and directed by Puck Lo. Illustrations created by Agustín Cepeda and Natalia Coachman. Web design support and development by Maria Lamardo. We also thank <ExternalLink href="https://www.queerblackediting.com/">Queer Black Editing</ExternalLink> for copyediting the toolkit, and Xenia Barahona for its translation.</p>
        <p>This project would not have been possible without the generous support of The Surdna Foundation.</p>
      <p>All materials developed for the toolkit are licensed under a <ExternalLink href="https://creativecommons.org/licenses/by-nc-sa/4.0/">Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International License</ExternalLink>, which means they may be adapted to fit your community needs; however, you must give appropriate credit, indicate if changes were made, and distribute your contributions under the same license as the original. Please review the guidelines linked above.</p>
      <h3>METHODOLOGY</h3>
      <p>This toolkit is a co-design project. In the first year of its development, community partner organizations identified, shared, and prioritized the problems, audiences, and potential tools needed to help influence change in their communities. In April 2018, the partners agreed upon a shared problem statement for the project: “We want people to be able to stay in their communities safely, affordably, and healthily. We want people to have a voice in shaping place. This is not currently happening in the communities of Chelsea, Everett, and East Boston.”</p>
      <p>Based on the problem statement, the community-partner organizations agreed on developing a multimedia organizing toolkit. The co-design process included reviewing existing toolkits and resources, developing the outline and components of the toolkit; co-creating the story and script for the film; and drafting, gathering, and reviewing content for each of the components. In March 2019, a co-design session was held to engage community-partner organizations and their community members and networks in the design and testing of the toolkit. The two-day co-design session and debrief consisted of conducting two idea labs to shape and generate content, three test labs to refine select components of the toolkit, and a small group screening and final review of the film cut. The feedback and evaluations from the co-design session were used to refine and finalize the multimedia organizing toolkit to fight displacement.</p>
        <h3>COMMUNITY AGREEMENTS</h3>
        <p>The modified Community Agreements adopted from <ExternalLink href="https://aorta.coop">Aorta</ExternalLink> below were used to create a safe space and grounding throughout the co-design process. Facilitators may find it useful to introduce these at the opening of each workshop.</p>
        <table class="community-agreements">
          <tbody>
          <tr>
            <td>ONE DIVA, ONE MIC<br><br>UNA DIVA, UN MICRÓFONO</td><td>Please, only 1 person speak at a time. (For those who need more time to process their words, or are less comfortable requesting airtime in a conversation, it could be useful to ask people to leave the space in between speakers.)</td>
          </tr>
          <tr>
            <td>NO ONE KNOWS EVERYTHING; TOGETHER WE KNOW A LOT<br><br>NADIE SABE TODO; JUNTOS SABEMOS MUCHO</td><td>This means we all get to practice being humble, because we have something to learn from everyone in the room. It also means we all have a responsibility to share what we know, including our questions, so that others may learn from us.</td>
          </tr>
          <tr>
            <td>MOVE UP, MOVE UP<br><br>MOVÁMONOS ROLES</td><td>If you&#39;re someone who tends to speak less, please move up into a role of speaking more. If you tend to speak a lot, please move up into a role of listening more. This is a twist on the more commonly known &quot;step up, step back.&quot; The &quot;up/up&quot; confirms that in both experiences, growth is happening. (You don&#39;t go &quot;back&quot; by learning to be a better listener.) Saying &quot;move&quot; instead of &quot;step&quot; recognizes that not everyone can step.</td>
          </tr>
          <tr>
            <td>WE CAN&#39;T BE ARTICULATE ALL THE TIME<br><br>NO LOGRAMOS ARTICULAR TODO EL TIEMPO</td><td>Often, people feel hesitant to participate in a workshop or meeting for fear of &quot;messing up&quot; or stumbling over their words. We want everyone to feel comfortable participating, even if you can&#39;t be as articulate as you&#39;d like.</td>
          </tr>
          <tr>
            <td>BE AWARE OF TIME<br><br>SER CONSCIENTES DEL TIEMPO</td><td>This is helpful for your facilitator, and helps respect everyone&#39;s time and commitment. Please come back on time from breaks, and refrain from speaking in long monologues.</td>
          </tr>
          <tr>
            <td>BE CURIOUS<br><br>SER CURIOSOS</td><td>We make better decisions when we approach our problems and challenges with questions (&quot;What if we... ?&quot;) and curiosity. Allow space for play, curiosity, and creative thinking.</td>
          </tr>
          <tr>
            <td>ANYTHING TO ADD?<br><br>¿ALGO MÁS?</td><td></td>
          </tr>
          </tbody>
        </table>
        <p><strong>NOTE:</strong> A few community agreements that participants often bring up that we don&#39;t tend to use or bring with us include: &quot;assume best intentions&quot; and &quot;default to trust.&quot; We don&#39;t use these agreements because when someone is unable to do this (say they&#39;re feeling untrusting of someone, or unsafe), having a community agreement telling us to do so isn&#39;t going to change anything. Put short, these agreements aren&#39;t always possible, especially when we take into consideration that people have been harmed by sexism, racism, homophobia, transphobia, and classism. Instead, we offer, &quot;we can&#39;t be articulate all the time,&quot; &quot;be generous with each other,&quot; and &quot;this is a space for learning,&quot; which capture the spirit of building up the necessary tools to take care of and protect themselves/ourselves.</p>
      </div>
    </main>
</template>

<script>
import ExternalLink from '../ExternalLink';
import ToolkitIntro from '../ToolkitIntro';

export default {
  name: "Overview",
  components: {
    ExternalLink,
    ToolkitIntro,
  }
};
</script>

<style scoped>
  .partnerLogos {
    background: white;
  }

  .partnerLogos img {
    margin: 0.5em auto;
  }

  h2 { border-bottom: 2px solid #cc0000; }

</style>