<template>
  <main class="workshop workshop__Overview">
    <ToolkitIntro :toolkit="$t('overviewToolkit')"></ToolkitIntro>
    <div class="workshop_content">
      <p class="translator-note"><strong>Nota de Traducción</strong>: Es del conocimiento de les colaboradores en este proyecto que quienes abogan por la neutralidad de género han propuesto diferente formas de abordar el tema del sexismo y el lenguaje binario en Español. A menudo se utilizan la ‘x’ o la ‘e’ para combinar la terminación masculina y femenina. En este proyecto, sin embargo, en un esfuerzo por ser inclusives en el uso del lenguaje, se tomó la decisión de utilizar el masculino, el femenino y el ‘e’ en artículos, pronombres y sustantivos. Por lo tanto, además de la terminación ‘o’ para el masculino y la terminación ‘a’, se ha utilizado también la terminación ‘e’ para género neutro o no binario. Por consiguiente, se observa en el documento el uso de el/la/le con sus correspondientes terminaciones. Por ejemplo: el/la/le facilitador/a/e.</p>
      <h2>Propósito, Valores y Metas</h2>
      <p>Este manual de uso multimedios para la organización comunitaria conlleva un conjunto de actividades, guías prácticas, planes de facilitación, y recursos que los organizadores y habitantes pueden utilizar para luchar contra el desplazamiento en sus comunidades.</p>
      <p>Chelsea y Everett, Massachusetts, están enfrentando la presión de inversores inmobiliarios, lo cual ha resultado en el acaparamiento de tierras por inversionistas, un alza en los precios de la vivienda, y el desplazamiento forzado (o expulsión) de los habitantes y las pequeñas empresas. En el estudio <ExternalLink href="https://www.clf.org/healthy-neighborhoods-study/">Healthy Neighborhoods Study (HNS)/ Vecindarios Saludables</ExternalLink> – el cual tiene como meta comprender la relación entre la urbanización, la salud y las condiciones vecinales en nueve comunidades de Massachusetts – se documentó los resultados de investigación entre los años 2016-2019 e indica que el 40% de los/las/ encuestados/as/e de Chelsea y el 31% de Everett esperan mudarse en los próximos cinco años. De esta misma investigación, el 72% de los/las/les encuestados/as/e de Chelsea y el 75% de Everett indicaron que están orgullosos de vivir en su comunidad. Sin embargo, el impacto del desplazamiento les afectará negativamente al igual que a otros individuos, familias y comunidades. Para obtener más información acerca del desplazamiento, incluyendo sus efectos sobre la salud, los resultados educativos, y el poder de la comunidad, vaya a la sección de Recursos para ver <ExternalLink href="https://www.urbandisplacement.org/pushedout">Pushed Out/Expulsados</ExternalLink>, un video corto producido por el Urban Displacement Project/Proyecto sobre Desplazamiento Urbano.</p>
      <p>Nosotros/as/e tenemos el poder para asegurar que nuestras comunidades sigan siendo opciones viables en las cuales los/las/le habitantes puedan vivir y prosperar. Es importante y oportuno que la comunidad local aborde el desplazamiento a medida que enfrentamos urbanizaciones a gran escala e iniciativas de planificación como el East Boston Master Plan/Plan East Boston, Chelsea Creek Municipal Harbor Plan/el Plan del Puerto Municipal del Río Chelsea o Encore Casino/el Casino Encore en Everett.</p>
      <p>Queríamos crear una guía para que los/las/les miembros de la comunidad trabajen juntos/as/e para proteger y preservar sus comunidades. Nuestro objetivo es que tú, el usuario, construyas poder a través del análisis colectivo (a partir de tu propio conocimiento), elabores objetivos y acciones conjuntas y desarrolles principios de referencia para el desarrollo equitativo.</p>

      <h3>Valores y Objetivos de Este Manual</h3>
      <ul>
        <li>Solidaridad: Cultivar el Poder Colectivo Utilizando el Conocimiento en la Sala</li>
        <li>Orientado a la Acción: Pensar + Hacer</li>
        <li>Híper-Accesibilidad: Multi-Generacional + Multilingüe</li>
        <li>Enraizado en la Comunidad: Híper-Local + Conjunto + Generalizable</li>
      </ul>
      <h2>¿Cómo utilizar este manual para luchar contra el desplazamiento en el Área Metropolitana de Boston?</h2>
      <p>A medida que las comunidades se protegen a sí mismas y se organizan contra el desplazamiento, este manual puede ser un recurso para que los/las/les habitantes trabajen juntos para crear el cambio. Las comunidades más vulnerables al desplazamiento tienen contacto más directo con lo que está ocurriendo en el terreno y saben qué soluciones funcionarán mejor para ellos/as/e. Este manual ayudará a los/las/les habitantes, los consejos vecinales, organizaciones y cualquier persona que esté organizándose contra el desplazamiento a conectarse de forma intencional y significativa. Este manual puede ayudar si una comunidad está tratando de entender cómo se presenta el desplazamiento, está tratando de crear conciencia entre los/las/les aliados, o está tratando de presionar por políticas específicas y soluciones programáticas. Puede ser usado para construir poder entre los/las/les habitantes, así como para impulsar cambios concretos dentro de la comunidad. Mediante el uso de estadísticas e historias, este manual toca la mente y los corazones de quienes se preocupan profundamente por su comunidad y pueden implementar un cambio.</p>
      <p>Este manual puede ser utilizado por cualquier persona que quiera trabajar con otros para combatir el desplazamiento en su comunidad, independientemente de su experiencia organizativa. ¡Con guías de facilitación accesibles, traducidas y de fácil lectura -  ya sea que seas organizador con experiencia, un proveedor de servicios, un inquilino o un propietario, un habitante de toda la vida o recién llegado - este manual es para ti y tu comunidad!</p>
      <p>La protección de los/las/les habitantes y los negocios locales, la producción de viviendas asequibles y la preservación del inventario de viviendas asequibles existente son piezas clave para prevenir el desplazamiento. Múltiples posibilidades radican en acciones que pueden y deben adoptarse - no existe una única solución. Los deseos y necesidades de una comunidad deben venir desde la base, y ¡esperamos que este manual te ayude a llegar allí!</p>

      <h2>¿Quién estuvo involucrado en la creación del Manual Anti-Desplazamiento del Área Metropolitana de Boston?</h2>
      <p>Este manual fue posible gracias a las contribuciones de muchas organizaciones y miembros/es de la comunidad, a quienes expresamos nuestra gratitud por compartir su tiempo, su experiencia y su compromiso.</p>
      <h3>ORGANIZACIONES SOCIAS DE LA COMUNIDAD</h3>
      <table>
        <tr>
          <td class="partnerLogos">
            <img src="../../assets/partnerLogos/echp-logo-no-sac-copy2.png" style="{ max-width: 8em; }" alt="Everett Community Health Partnership Logo">
            <img src="../../assets/partnerLogos/CHA_logo.png" alt="Cambridge Health Alliance Logo">
          </td>
          <td><p><a href="https://www.challiance.org/community/everett-health-partnership" class="org__name">Everett Community Health Partnership</a> (ECHP por sus siglas en inglés) es una coalición comunitaria multi-sectorial facilitada por el departamento sobre Avances en Salud Comunitaria de Cambridge Health Alliance. ECHP busca mejorar la salud y calidad de vida en Everett, creando oportunidades para abordar estratégicamente los retos a los que se enfrenta la comunidad, desarrollando la consciencia sobre el impacto de la urbanización en el bienestar de la comunidad y asegurando que los datos claves sobre la salud comunitaria estén ampliamente disponibles. ECHP es un socio en investigación comunitaria colaborando con Healthy Neighborhoods Study, un estudio de investigación y acción participativa co-liderado por la Conservation Law Foundation, Mariana Arcaya y MIT Community Innovators Lab (CoLab).</p>
            <p class="italic">Kathleen O'Brien trabaja para Cambridge Health Alliance, Iliana Panameno e Yrma Fiestas trabajan para La Comunidad, Inc.</p>
          </td>
        </tr>
        <tr>
          <td class="partnerLogos"><img src="../../assets/partnerLogos/GreenRootsLogo_flat.png" alt="Green Roots Logo">
          </td>
          <td>
            <p><a href="https://www.greenrootschelsea.org/" class="org__name">GreenRoots</a>, es una organización comunitaria, dirigida por habitantes, que se dedica a mejorar el medio ambiente urbano y la salud pública en Chelsea y las comunidades circundantes. GreenRoots lleva a cabo su labor con un profundo involucramiento y empoderamiento de la comunidad, el liderazgo juvenil, y la implementación de proyectos innovadores y campañas. GreenRoots colabora en investigación comunitaria con Healthy Neighborhoods Study, un estudio de investigación y acción participativa liderado por Mariana Arcaya y CoLab.</p>
            <p class="italic">Maria Belen Power, Indira Garmendia Alfaro</p>
          </td></tr>
      </table>
      <p>Agradecemos a todes los/las/les socios y miembros/es de la comunidad por su participación y sus detallados comentarios en la sesión de diseño conjunto del manual. </p>
      <p>Agradecemos a Lisa Owens y Andrés del Castillo quienes trabajan para <a href="https://www.clvu.org">City Life/Vida Urbana</a> por su participación y sus aportes al concepto inicial y el diseño conjunto de este manual.</p>
      <h3>ORGANIZACIONES COORDINADORAS</h3>
      <table><tr><td class="partnerLogos"><img src="../../assets/partnerLogos/RAD_logo3_large---transparency.png" alt="Research Action Design Logo">
      </td>
        <td>
          <p><a href="https://rad.cat" class="org__name">Research Action Design (RAD)</a> utiliza investigación liderada por la comunidad, diseño colaborativo de tecnología y medios, y estrategias digitales seguras para desarrollar el poder de los movimientos sociales de base. RAD es un colectivo propiedad de sus trabajadores cuyos proyectos se basan en las necesidades y el liderazgo de las comunidades en la lucha por la justicia y la liberación. </p>
          <p class="italic">Caroline Rivas, Chris Schweidler, Tim Stallmann</p>
        </td>
      </tr>
        <tr><td class="partnerLogos"><img src="../../assets/partnerLogos/colab-logo.png" alt="MIT Community Innovators Lab Logo">
        </td>
          <td>
            <p><a href="https://www.colab.mit.edu" class="org__name">MIT Community Innovators Lab (CoLab)</a> apoya el desarrollo y uso de los conocimientos de las comunidades excluidas para profundizar el compromiso cívico, mejorar la práctica de la comunidad, informar sobre las políticas, movilizar bienes comunitarios y generar riqueza compartida. Creemos en el poder del conocimiento impulsado por la comunidad y su potencial para innovar soluciones a desafíos urbanos complejos. CoLab facilita el intercambio de conocimientos y recursos entre MIT y las organizaciones de la comunidad, involucrando a los estudiantes y profesores para que sean profesionales practicantes de este enfoque para el cambio comunitario y la sostenibilidad.</p>
            <p class="italic">Allegra Williams, Andrew Binet, Agustín Cepeda, Natalia Coachman, Taina McField, Taylor Cain.</p>
          </td></tr>
      </table>
      <p>La película, <b>Desplazada</b> para el manual fue producida y dirigida por Puck Lo. Las ilustraciones fueron creadas por Agustín Cepeda y Natalia Coachman. También agradecemos a <ExternalLink href="https://www.queerblackediting.com/">Queer Black Editing</ExternalLink> por las correcciones al manual, y Xenia Barahona por su traducción, y Maria Lamardo por el soporte y desarollo del diseño Web.</p>
      <p>Este proyecto no habría sido posible sin el generoso apoyo de Surdna Foundation.</p>
      <p>Todos los materiales desarrollados para el manual están registrados bajo la <ExternalLink href="https://creativecommons.org/licenses/by-nc-sa/4.0/">licencia Creative Commons Attribution-ShareAlike 4.0 Internacional</ExternalLink>, lo que significa que pueden ser adaptados para satisfacer las necesidades de tu comunidad; sin embargo, debes dar el crédito apropiado, indicar si se han realizado cambios, y distribuir tus contribuciones bajo la misma licencia que el original. Revisar las normas vinculadas anteriormente.</p>
      <h3>METODOLOGÍA</h3>
      <p>Este manual es un proyecto de diseño conjunto. En el primer año de su desarrollo, las organizaciones comunitarias socias identificaron, compartieron y priorizaron los problemas, las audiencias, y posibles instrumentos necesarios para ayudar a influenciar el cambio en sus comunidades. En abril de 2018, las organizaciones socias acordaron una declaración conjunta del problema para el proyecto: "Queremos que las personas puedan permanecer en sus comunidades de forma segura, saludable y con vivienda asequible. Queremos que la gente tenga voz en la configuración de sus comunidades. Esto no está ocurriendo actualmente en las comunidades de Chelsea, Everett ni East Boston".</p>
      <p>Con base en la declaración del problema, las organizaciones socias de la comunidad acordaron elaborar un manual de uso multimedios para organizar. El proceso de diseño conjunto incluyó la revisión de manuales y recursos existentes, desarrollar el esquema y los componentes del manual; la co-creación de la historia y el guion de la película; y la elaboración, recopilación y revisión del contenido para cada uno de los componentes. En marzo de 2019, se llevó a cabo una sesión de diseño conjunto para involucrar a las organizaciones comunitarias socias, sus miembros/es y redes en el diseño y en la prueba del manual. La sesión de dos días de co-diseño y evaluación consistió en la realización de dos laboratorios de ideas para configurar y generar contenido, tres laboratorios de pruebas para refinar la selección de los componentes del manual, y una presentación de la película a un pequeño grupo y una revisión final de la película editada. Los comentarios y las evaluaciones de la sesión de diseño conjunto fueron utilizados para refinar y finalizar el manual de uso multimedios para la organización comunitaria para luchar contra el desplazamiento.</p>

      <h3>Acuerdos Comunitarios</h3>
      <p>Se utilizaron los Acuerdos Comunitarios modificados de <ExternalLink href="https://aorta.coop">Aorta</ExternalLink> - mencionados abajo - para crear un espacio seguro y de conexión durante el proceso de co-diseño. Es posible que les facilitadores encuentren de utilidad el introducir estos acuerdos al inicio de cada sesión.</p>
      <table class="community-agreements">
        <tbody>
        <tr>
          <td>ONE DIVA, ONE MIC<br><br>UNA DIVA, UN MICRÓFONO</td><td>Por favor, solamente 1 persona puede hablar a la vez. (Para las personas que necesitan más tiempo para procesar sus palabras, o que se sienten menos cómodas solicitando su participación en una conversación, podría ser útil solicitarles que aprovechen el espacio entre las(os) exponentes para prepararse)</td>
        </tr>
        <tr>
          <td>NO ONE KNOWS EVERYTHING; TOGETHER WE KNOW A LOT<br><br>NADIE SABE TODO; JUNTOS SABEMOS MUCHO</td><td>Esto quiere decir que todes podemos practicar ser humildes, porque tenemos algo que aprender de cada persona en la habitación. También significa que todes tenemos la responsabilidad de compartir lo que sabemos, incluyendo nuestras preguntas, de manera que otras personas puedan aprender de nosotros también.</td>
        </tr>
        <tr>
          <td>MOVE UP, MOVE UP<br><br>MOVÁMONOS ROLES</td><td>Si eres alguien que tiende a hablar menos, por favor anímate a moverte a una función donde hables más.  Si tiendes a hablar mucho, por favor anímate a moverte a una función donde puedas escuchar más. Esto es una variación del comúnmente conocido &quot;paso adelante, paso atrás&quot;. El &quot;adelante/adelante&quot; confirma que en ambas experiencias está dándose un crecimiento. (No retrocedes al aprender a ser un mejor oyente).  Decir &quot;moverse&quot; en vez de &quot;dar un paso&quot; reconoce que no todas las personas pueden dar un paso.</td>
        </tr>
        <tr>
          <td>WE CAN&#39;T BE ARTICULATE ALL THE TIME<br><br>NO LOGRAMOS ARTICULAR TODO EL TIEMPO</td><td>Con frecuencia, las personas se sienten indecisas de participar en un taller o reunión por temor a &quot;equivocarse&quot; o hablar entrecortado. Queremos que todes se sientan cómodes participando, incluso si no pueden ser tan elocuentes como quisieran.</td>
        </tr>
        <tr>
          <td>BE AWARE OF TIME<br><br>SER CONSCIENTES DEL TIEMPO</td><td>Esto es útil para la persona facilitando, y ayuda a respetar el tiempo y compromiso de cada persona. Por favor regresen a tiempo de los descansos, y eviten hablar por medio de largos monólogos.</td>
        </tr>
        <tr>
          <td>BE CURIOUS<br><br>SER CURIOSOS</td><td>Tomamos mejores decisiones cuando abordamos nuestros desafíos y problemas por medio de preguntas (&quot;Qué tal si nosotros …?&quot;) y curiosidad. Permite espacios para que se realicen juegos y se desarrolle la curiosidad y el pensamiento creativo.</td>
        </tr>
        <tr>
          <td>ANYTHING TO ADD?<br><br>¿ALGO MÁS?</td><td></td>
        </tr>
        </tbody>
      </table>
      <p><strong>NOTA:</strong> Algunes participantes con frecuencia mencionan algunos acuerdos comunitarios que tendemos a no usar o incorporar, estos incluyen: &quot;asumir las mejores intenciones&quot; y &quot;confiar de hecho&quot;. No usamos estos acuerdos debido a que si alguien es incapaz de hacer esto (decir que sienten desconfianza o se sienten inseguros de alguien), el tener un acuerdo comunitario que dice que deben hacer tal cosa realmente no va a cambiar nada. En otras palabras, estos acuerdos no son siempre posibles, especialmente cuando tomamos en consideración que algunas personas han sido heridas por el sexismo, el racismo, la homofobia, transfobia, y el clasismo. En vez, sugerimos, &quot;no podemos ser elocuentes todo el tiempo&quot;, &quot;ser generosos con el resto&quot;, y &quot;este es un espacio para aprender&quot;, los cuales reflejan el espíritu de construir las herramientas necesarias para cuidarnos y protegernos nosotres mismes.</p>
    </div>
  </main>
</template>

<script>
  import ToolkitIntro from '../ToolkitIntro';
  import ExternalLink from '../ExternalLink';

  export default {
    name: "Overview",
    components: {
      ExternalLink,
      ToolkitIntro,
    }
  };
</script>

<style scoped>
  .partnerLogos {
    background: white;
  }

  .partnerLogos img {
    margin: 0.5em auto;
  }

  h2 { border-bottom: 2px solid #cc0000; }

</style>